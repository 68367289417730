/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import "semantic-ui-css/semantic.min.css"

import "semantic-ui-less/semantic.less"
import "./src/pages/index.scss"