import { Card, Icon, Button, Segment, Label, Header, List } from 'semantic-ui-react';
import { Link } from 'gatsby';
import * as React from 'react';
export default {
  Card,
  Icon,
  Button,
  Segment,
  Label,
  Header,
  List,
  Link,
  React
};